/* eslint-disable */
import React, { useEffect } from "react";
import { Spinner, Image } from "react-bootstrap";
import LogoImage from "../../assets/img/logo-white.png";
import { useLocation } from "react-router-dom";

const HelpDesk = () => {
    const Location = useLocation();
    const GetFrameNumberFromUrl = Location?.search?.split("=")[1];
    const Playstoreurl = "https://play.google.com/store/apps/details?id=com.emotorad.dealer&hl=en_IN";

    useEffect(() => {
        if(GetFrameNumberFromUrl) {
            if(navigator.userAgent.toLowerCase().indexOf("android") > -1){
                // window.location.href = `market://details?id=com.emotorad.dealer&frame=${GetFrameNumberFromUrl}`;
                window.location.href = Playstoreurl;
            } else if(navigator.userAgent.toLowerCase().indexOf("iphone") > -1){
                window.location.href = window.location.href;
            } else{
                window.location.href = window.location.href;
            };
        };
    },  [ GetFrameNumberFromUrl ])

    return(<div className="loginWrapper MainregisterproductWrapper">
        <h1 className="logoheader">
            <Image src={LogoImage} alt="logo" />
        </h1>
        <div className="helpdesk_app_loading">
            <Spinner />
            <h1>Please wait you will be redirected to the EMotorad Dealer App</h1>
        </div>
    </div>);
}   

export default HelpDesk;